import React, { useState } from "react";
import { loginUser } from "../services/agent";

function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = () => {
    loginUser(username, password)
      .then((data) => {
        onLogin(data.token);
        setErrorMessage("");
      })
      .catch((error) => {
        console.error("Login error:", error);
        setErrorMessage(
          "Login failed. Please check your credentials and try again."
        );
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "50px",
      }}
    >
      <div className="card p-4 mb-4" style={{ width: "400px" }}>
        <img
          src="logo.png"
          alt="Logo"
          className="img-fluid mx-auto d-block mb-3"
          style={{ maxWidth: "150px" }}
        />
        <h3 className="text-center mb-3">Login</h3>
        <div className="form-group">
          <label htmlFor="usernameInput">Username:</label>
          <input
            type="text"
            id="usernameInput"
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="passwordInput">Password:</label>
          <input
            type="password"
            id="passwordInput"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="btn btn-primary mt-3" onClick={handleLogin}>
          Login
        </button>
        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
