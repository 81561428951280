import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import EmailValidator from "./components/EmailValidator";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import { localStorageManager } from "./services/localStorageManager";
import WorkerStatus from "./components/WorkersStatus";

function App() {
  const [token, setToken] = useState();

  useEffect(() => {
    const localToken = localStorageManager.getItem("token");
    if (localToken) {
      setToken(localToken);
    }
  }, [token]);

  function onLoginHandler(incomingToken) {
    localStorageManager.setItem("token", incomingToken);
    setToken(incomingToken);
  }

  return (
    <div
      style={{
        backgroundImage: "url(/loginBackground.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh"
      }}
    >
      {!token ? <Login onLogin={onLoginHandler} /> : (
        <>
        <EmailValidator />
        <WorkerStatus />
        </>
        )}
    </div>
  );
}

export default App;
