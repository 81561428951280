import React from 'react';

const FileInput = ({ handleFileChange }) => {
  const fileInputRef = React.useRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="col">
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        id="fileInput"
        className="form-control-file d-none" // Use Bootstrap's "d-none" to hide it
        accept=".csv"
        onChange={handleFileChange}
      />
      
      {/* Custom button to trigger file input */}
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClick}
      >
        Upload CSV File
      </button>
    </div>
  );
};

export default FileInput;
