export const localStorageManager = {
    // Save data to localStorage
    setItem: (key, value) => {
      try {
        const serializedValue = JSON.stringify(value); // Convert value to a JSON string
        localStorage.setItem(key, serializedValue); // Store the stringified value
      } catch (error) {
        console.error("Error saving to localStorage", error);
      }
    },
  
    // Retrieve data from localStorage
    getItem: (key) => {
      try {
        const serializedValue = localStorage.getItem(key); // Get the value from localStorage
        return serializedValue ? JSON.parse(serializedValue) : null; // Parse the value back to its original form
      } catch (error) {
        console.error("Error reading from localStorage", error);
        return null;
      }
    },
  
    // Remove data from localStorage
    removeItem: (key) => {
      try {
        localStorage.removeItem(key); // Remove the item from localStorage
      } catch (error) {
        console.error("Error removing from localStorage", error);
      }
    },
  
    // Clear all data from localStorage
    clearAll: () => {
      try {
        localStorage.clear(); // Clear all data from localStorage
      } catch (error) {
        console.error("Error clearing localStorage", error);
      }
    }
  };