import React, { useEffect, useState } from "react";
import { fetchEmailsProgress } from "../services/agent";
import { ProgressBar } from "react-bootstrap";
import { localStorageManager } from "../services/localStorageManager";

export default function WorkerStatus() {
  const [emailsProgress, setEmailsProgress] = useState(null);
  const [estimationTime, setEstimationTime] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const VALIDATION_SPEED = 175;

  // Fetch email validation progress data
  const fetchEmailsProgressData = () => {
    setLoading(true);
    const token = localStorageManager.getItem("token");
    if (token) {
      fetchEmailsProgress(token)
        .then((data) => {
          setEmailsProgress(data);
          setError("");
          calculateEstimationTime(data);
        })
        .catch((error) => {
          console.error("Failed to fetch emails progress:", error);
          setError("Failed to load emails progress. Please try again.");
        })
        .finally(() => setLoading(false));
    } else {
      setError("User authentication is required.");
      setLoading(false);
    }
  };

  const calculateEstimationTime = (progress) => {
    if (progress) {
      const remainingEmails =
        progress.emailsPending +
        progress.emailsInProgress;
      const timeInMinutes = remainingEmails / VALIDATION_SPEED;
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = Math.ceil(timeInMinutes % 60);
      setEstimationTime({ hours, minutes });
    }
  };

  // Poll for emails progress updates every 10 seconds
  useEffect(() => {
    fetchEmailsProgressData();
    const interval = setInterval(fetchEmailsProgressData, 10000);
    return () => clearInterval(interval);
  }, []);

  const calculatePercentage = (count) => {
    return totalEmails > 0 ? ((count / totalEmails) * 100).toFixed(2) : 0;
  };

  const totalEmails = emailsProgress
    ? emailsProgress.emailsInProgress +
      emailsProgress.emailsValid +
      emailsProgress.emailsInvalid +
      emailsProgress.emailsCatchAll + 
      emailsProgress.emailsPending
    : 0;

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Email Validation Progress</h2>

      <div className="text-center mb-3">
        <button
          className="btn btn-primary"
          onClick={fetchEmailsProgressData}
          disabled={loading}
        >
          {loading ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </>
          ) : (
            <>
              Refresh <i className="bi bi-arrow-clockwise ms-1"></i>
            </>
          )}
        </button>
      </div>

      {error && (
        <div className="alert alert-danger text-center">{error}</div>
      )}

      {emailsProgress && totalEmails > 0 ? (
        <div className="mt-4">
          <h5>Validation Progress</h5>
          <ProgressBar>
            {emailsProgress.emailsValid > 0 && (
              <ProgressBar
                now={(emailsProgress.emailsValid / totalEmails) * 100}
                label={`Valid (${emailsProgress.emailsValid})`}
                variant="success"
                key={1}
              />
            )}
            {emailsProgress.emailsInvalid > 0 && (
              <ProgressBar
                now={(emailsProgress.emailsInvalid / totalEmails) * 100}
                label={`Invalid (${emailsProgress.emailsInvalid})`}
                variant="danger"
                key={2}
              />
            )}
            {emailsProgress.emailsCatchAll > 0 && (
              <ProgressBar
                now={(emailsProgress.emailsCatchAll / totalEmails) * 100}
                label={`CatchAll (${emailsProgress.emailsCatchAll})`}
                variant="warning"
                key={3}
              />
            )}
            {emailsProgress.emailsPending > 0 && (
              <ProgressBar
                now={(emailsProgress.emailsPending / totalEmails) * 100}
                label={`Pending (${emailsProgress.emailsPending})`}
                variant="info"
                key={4}
              />
            )}
          </ProgressBar>
          {estimationTime && (
            <div className="mt-3 text-center">
              <h6>
                Estimated Time Remaining:{" "}
                <strong>
                  {estimationTime.hours > 0
                    ? `${estimationTime.hours}h `
                    : ""}
                  {estimationTime.minutes}m
                </strong>
              </h6>
            </div>
          )}
          <div className="table-responsive mt-4">
            <table className="table table-bordered table-hover align-middle">
              <thead className="table-light">
                <tr>
                  <th>Status</th>
                  <th>Count</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Valid</td>
                  <td>{emailsProgress.emailsValid}</td>
                  <td>{calculatePercentage(emailsProgress.emailsValid)}%</td>
                </tr>
                <tr>
                  <td>Invalid</td>
                  <td>{emailsProgress.emailsInvalid}</td>
                  <td>{calculatePercentage(emailsProgress.emailsInvalid)}%</td>
                </tr>
                <tr>
                  <td>CatchAll</td>
                  <td>{emailsProgress.emailsCatchAll}</td>
                  <td>{calculatePercentage(emailsProgress.emailsCatchAll)}%</td>
                </tr>
                <tr>
                  <td>Pending</td>
                  <td>{emailsProgress.emailsPending}</td>
                  <td>{calculatePercentage(emailsProgress.emailsPending)}%</td>
                </tr>
                <tr className="table-secondary">
                  <td><strong>Total</strong></td>
                  <td><strong>{totalEmails}</strong></td>
                  <td><strong>100%</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="alert alert-info text-center">
          No validation is currently in progress.
        </div>
      )}
    </div>
  );
}
