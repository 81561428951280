import React, { useState } from "react";
import axios from "axios";
import { localStorageManager } from "../services/localStorageManager";

export default function DownloadCsvButton() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const downloadCsv = async () => {
    setLoading(true);
    setError("");

    try {
      const token = localStorageManager.getItem("token");
      if (!token) {
        setError("Authentication is required.");
        setLoading(false);
        return;
      }

      const apiDomain =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_API_DOMAIN_PROD
          : process.env.REACT_APP_API_DOMAIN;

      // Make the request to download the CSV
      const response = await axios.get(`${apiDomain}/api/ValidateEmails/download`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob' // Important: Set response type to 'blob' to handle binary data
      });

      // Create a link to download the file
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'email_validation_results.csv');
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and link element after download
      link.remove();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Error downloading the file:", error);
      if (error.response && error.response.status === 404) {
        setError("No email validation results available.");
      } else {
        setError("Failed to download the file. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-3">
      <button
        className="btn btn-success"
        onClick={downloadCsv}
        disabled={loading}
      >
        {loading ? "Downloading..." : "Download CSV"}
      </button>

      {error && (
        <div className="alert alert-danger mt-3" role="alert">
          {error}
        </div>
      )}
    </div>
  );
}
